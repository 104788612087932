import "./bootstrap"
import "../css/app.css"
import { setupSentry } from "@/Plugins/sentry"
import { createApp, DefineComponent, h } from "vue"
import { createInertiaApp, Link, usePage } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import i18nVue from "./i18n"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m"
import { modal } from "momentum-modal"
import VueTelInput from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Studio"

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name: string) => {
        return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>("./Pages/**/*.vue"))
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        setupSentry(app)

        app.use(plugin)
            .use(modal, {
                resolve: (name) => resolvePageComponent(name, import.meta.glob("./Pages/**/*.vue")),
            })
            .use(ZiggyVue)
            .use(i18nVue, {
                fallbackLang: "",
                resolve: (lang) => {
                    lang = usePage().props.auth.user ? usePage().props.auth.user.language.toLowerCase() : null
                    return lang ? usePage().props.translations[lang] : Object.values(usePage().props.translations)[0]
                },
            })
            .use(VueTelInput)
            .use(FloatingVue)
            .component("Link", Link)
            .mount(el)
    },
    progress: {
        color: "#5952F6",
    },
})
