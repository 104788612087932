import { I18n } from "laravel-vue-i18n"
import { usePage } from "@inertiajs/vue3"
import { Locales } from "@/enums"

const DEFAULT_PLUGIN_OPTIONS = {
    shared: true,
}

export function trans(key, replacements) {
    const instance = I18n.getSharedInstance()

    const result = instance.trans(key, replacements)

    if (result !== key && result !== undefined) {
        return result
    }

    return usePage().props.translations[Locales.NL][key]
}

export default {
    install: (app, options) => {
        options = Object.assign(Object.assign({}, DEFAULT_PLUGIN_OPTIONS), options)
        const i18n = options.shared ? I18n.getSharedInstance(options, true) : new I18n(options)
        app.config.globalProperties.$t = trans
        app.config.globalProperties.$tChoice = (key, number, replacements) =>
            i18n.transChoice(key, number, replacements)
        app.provide("i18n", i18n)
        app.provide("trans", trans)
    },
}
