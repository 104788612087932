import type { App } from "vue"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

export function setupSentry(app: App<Element>) {
    if (import.meta.env.VITE_APP_ENV !== "local") {
        Sentry.init({
            app,
            environment: import.meta.env.VITE_APP_ENV,
            dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
            release: "studio@main",
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 0.0,
            trackComponents: true,
        })
    }
}
