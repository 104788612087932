export const EpisodeStatus = {
    Not_Started: {
        value: 2,
        pillColor: "bg-[#3F3E50]",
        translationKey: "episode.status.not_started",
    },
    In_Progress: {
        value: 0,
        pillColor: "bg-[#60392b]",
        translationKey: "episode.status.in_progress",
    },
    Completed: {
        value: 1,
        pillColor: "bg-[#3e3a93]",
        translationKey: "episode.status.completed",
    },
}

export const UserRole = {
    Owner: "owner",
    Editor: "editor",
    Reviewer: "reviewer",
}

export const Locales = {
    NL: "nl",
    EN: "en",
}
